import { useCallback, useState } from "react";

import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";

import { loginUserOnShopify, setupLoginUserShopify } from "@/API/account";
import { identify, track } from "@/helpers/analytics";

type FormValues = {
	email: string;
	password: string;
};

export const useLogin = () => {
	const { register, handleSubmit } = useForm<FormValues>({ mode: "onBlur" });

	const [loading, setIsLoading] = useState(false);
	const [error, setErrorMsg] = useState<string | null>(null);

	const { push, query } = useRouter();

	const doLoginShopify = useCallback(
		async (email: string, password: string) => {
			const result = await loginUserOnShopify(email, password);
			if (result?.customerUserErrors?.[0]?.code === "UNIDENTIFIED_CUSTOMER") {
				setErrorMsg("Incorrect username/password");

				return;
			}

			if (result && result.customerAccessToken) {
				const customerInfo = await setupLoginUserShopify(
					result.customerAccessToken.accessToken
				);

				const newCustomerInfo = { ...customerInfo };

				if (customerInfo?.id?.includes("/")) {
					newCustomerInfo.id = window.btoa(customerInfo.id);
				}

				push({
					pathname: "/login/transfer/new-password",
					query: {
						name: customerInfo.firstName,
						email: customerInfo.email,
						id: newCustomerInfo.id,
					},
				});
			}
		},
		[push]
	);

	const doLogin = useCallback(
		async (data: Record<string, string>) => {
			setIsLoading(true);

			const info = await signIn("credentials", {
				redirect: false,
				email: data.email,
				password: data.password,
			});

			if (info?.error) {
				if (info.error === "Shopify transfer user set new password") {
					doLoginShopify(data.email, data.password);
					setIsLoading(false);

					return;
				}
				setIsLoading(false);
				setErrorMsg("Incorrect username/password");

				return;
			}
			setIsLoading(false);
			setErrorMsg(null);

			identify({
				email: data.email,
			});

			track("User logged in", {
				category: "account",
				email: data.email,
			});

			const { referrer, ...params } = query;
			push({
				pathname: referrer ? (referrer as string) : "/account/dashboard",
				query: { ...params },
			});
		},
		[doLoginShopify, push, query]
	);

	return {
		loading,
		doLogin,
		handleSubmit,
		register,
		error,
	};
};
