import { getCsrfToken } from "next-auth/react";
import { unstable_getServerSession } from "next-auth/next";
import { GetServerSideProps } from "next";
import Link from "next/link";

import { Input } from "@/components/FormElements";
import { Button } from "@/components/FormElements/Button";
import { Layout } from "@/components/Layout";
import { _Head } from "@/components/_head";
import { useLogin } from "@/pagesComponents/Login";
import { SlapLabel } from "@/components/FormElements/SlapLabel";
import { PageData } from "@/types/page";
import { initializeApollo } from "@/API/apolloClient";
import { fetchFunnelPageDataBySlug } from "@/API/queries";
import { ImageAndText } from "@/components/pageSections/ImageAndText";
import { BenefitsBar } from "@/components/pageSections/BenefitsBar";
import { ReviewsSection } from "@/components/pageSections/ReviewsSection";
import { FullWidth } from "@/components/pageSections/FullWidth";
import { SlapLabelVariants } from "@/types/layout";

import { authOptions } from "../api/auth/[...nextauth]";

type Props = {
	csrfToken: string;
	pageData: PageData;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
	const session = await unstable_getServerSession(
		context.req,
		context.res,
		authOptions
	);
	if (session) {
		return {
			redirect: {
				destination: "/account/dashboard",
				permanent: false,
			},
		};
	}

	const csrfToken = await getCsrfToken();

	try {
		const apolloClient = initializeApollo();
		const { data } = await apolloClient.query({
			query: fetchFunnelPageDataBySlug,
			variables: { slug: "login" },
			context: { clientName: "dato" },
		});

		if (!data.allFunnelPages || data.allFunnelPages.length < 1) {
			return {
				notFound: true,
			};
		}

		return {
			props: {
				csrfToken: csrfToken,
				pageData: data.allFunnelPages[0],
			},
		};
	} catch (err) {
		console.log(err);
		return { notFound: true };
	}
};

const Page = ({ csrfToken, pageData }: Props) => {
	const { register, doLogin, handleSubmit, error, loading } = useLogin();

	const loginForm = (
		<form onSubmit={handleSubmit(doLogin)}>
			<div className="container containerNoPad">
				<input
					name="csrfToken"
					type="hidden"
					{...register}
					defaultValue={csrfToken}
				/>
				<Input
					name="email"
					type="email"
					id="email"
					labelType="small"
					label="Email address"
					leftAlign
					register={register}
				/>

				<Input
					name="password"
					type="password"
					id="password"
					labelType="small"
					leftAlign
					label="Password"
					register={register}
				/>

				<p className="text-right">
					<Link href="/login/reset-password" className="underline">
						<a>Forgotten your password?</a>
					</Link>
				</p>

				{error && (
					<p className="text-center no-bottom">
						<SlapLabel wrap fullWidth variant={SlapLabelVariants.errors}>
							{error}
						</SlapLabel>
					</p>
				)}

				<Button isWorking={loading}>Login</Button>
			</div>
		</form>
	);

	const pageDataSections = pageData.sections.map((section, i) => {
		switch (section.__typename) {
			case "LandingImageTextRecord":
				return (
					<ImageAndText content={section} key={i}>
						{loginForm}
					</ImageAndText>
				);
			case "LandingBenefitsBarRecord":
				return <BenefitsBar content={section} key={i} />;
			case "LandingReviewRecord":
				return <ReviewsSection hasPet={true} content={section} key={i} />;
			case "LandingFullWidthRecord":
				return <FullWidth content={section} key={i} />;
			default:
				return <></>;
		}
	}, []);

	return (
		<Layout hasFooter>
			<_Head
				title="Pure Pet Food Signup"
				metaDescription="Create a Pure Pet Food Plan"
				metaOGTitle="Pure Pet Food Signup"
				metaOGDescription="Create a Pure Pet Food Plan"
				metaRobots
			/>
			{pageDataSections}
		</Layout>
	);
};

// eslint-disable-next-line import/no-default-export
export default Page;
